@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

@font-face {
  font-family: 'Varela Round';
  src: local('/src/assets/fonts/VarelaRound-Regular.ttf');
}

body {
  margin: 0;
  --bs-font-sans-serif: 'Varela Round';
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  margin: 0;
}

$theme-colors: (
    "primary": orange,
    "info": tomato,
    "danger": teal
);

.problema-subtitulo {
  background-color: #ff9801;
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: #0c0b09;
}

.seccion-subtitulo {
  width: 500px;
  color:white;
  font-size: 1.5rem;
  padding-bottom: 2rem;
}  

.problema-subtitulo-2 {
  background-color: white;
  color:7A7A7A;
  font-size: 1.5rem;
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: #0c0b09;
  padding-top: 1rem;
}

.text-nosotros {
  text-align: center;
}

.copyright-text {
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #f98c07;
}
.header_section {
  font-family: "VarelaRound", sans-serif;
  background-color: #ff9801;
  color: #fff;
  z-index: 10 !important;
  position: relative;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
  padding-left: 12px;
  padding-right: 12px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.custom_nav-container .navbar-toggler {
  outline: none;
  margin-right: 1rem;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  background-image: url(./assets/images/menu.png);
}

.navbar-collapse {
  justify-content: flex-end !important;
}

.nav-link:hover {
  color: #fefefe;
}

.logo_box {
  width: 200px;
}

.logo_box img {
  width: 200px;
}

.welcome_section{
  display: flex;
  position: relative;
  width: max-width;
  max-height: 440px;
  height: 439px;
  background-image: url(./assets/images/main-hero-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100% !important;
  box-shadow: 0px 0px 4px 0px rgb(0 0 0);
  z-index: 0;
}

.fixed_company-detail {
  position: relative;
  top: 20%;
  right: 5%;
  color: #f0f0f0;
  text-shadow: -2px 4px 6px rgb(36 15 10 / 50%);
}

.titulo {
  text-transform: uppercase;
}

.icono-welcome img{
  height: 140px;
}

.welcome_section .col-md-6 {
  padding: 0 1%;
}

.cZPUAL {
  border: 2px solid #26272b !important;
  font-size: 28px;
  z-index: 10;
  height: 48px !important;
  width: 48px !important;
}
.carousell-class {
  background-color: #26272b !important;
  box-shadow: 0px 0px 4px 0px rgb(0 0 0);
}

.carousel {
  background-color: #26272b !important;
  position: relative;
  max-width: 1440px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;

}

.problema-titulo {
  background-color: #ff9801;
  color:white;
  font-size: 3.5rem;
  letter-spacing: 4px;
  font-weight: 700;
  width: 100%;
  padding-top: 1.5rem;
}

.icono-razones {
  color: #7A7A7A;
  font-weight: 600;
  font-size: 3rem !important;
  background-color: white;
  vertical-align: bottom;
  display: inline-block;
  height: 2.5rem !important;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.gray-background {
  max-width: none !important;
  background-color: #26272b !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  box-shadow: 0px 0px 4px 0px rgb(0 0 0);
  position: relative;
}

.orange-background {
  max-width: none !important;
  background-color: #ff9801 !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  position: relative;
  z-index: 10;
}

.white-background {
  max-width: none !important;
  background-color: white !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.problema-card {
  padding-top: 6rem;
}

@media (max-width: 2560px) {
  .titulo-welcome {
      font-family: Roboto, sans-serif;
      font-weight: 700;
      letter-spacing: 5px;
      flex: 0 0 auto;
      font-size: 5rem;
      text-align: right;
      margin-right: -8px;
  }
  
  .subtitulo-welcome {
    font-size: 2rem;
    text-align: right;
  }

  .ventaja-card {
    flex: 0 0 auto !important;
    width: 20% !important;
    padding-bottom: 4rem;
    padding-top: 2rem;
  }

  .seccion-subtitulo {
    width: 700px !important;
    color: white;
    font-size: 1.1rem !important;
    padding-bottom: 2rem;
  }

  .problema-card {
    flex: 0 0 auto !important;
    width: 20% !important;
    align-content: center;
    padding-bottom: 3rem;
  }
  
  .titulo-problema {
    color: #ff9801;
    font-weight: 600;
    font-size: 1.5rem !important;
    background-color: white;
    vertical-align: middle;
    display: grid;
    height: 60px !important;
    width: 100% !important;
    margin-right: auto;
    align-content: center;
  }
  
  
  .solucion-postventa {
    width: 50%;
    right: 17% !important;
    top: 50% !important;
    left: 0% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-1 {
    font-size: 3rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    margin-right: 5% !important;
    height: 4rem !important;
    width: 95% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-1 {
    background-color: #ff9801;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    margin-left: 10%;
    list-style-type: none;
    text-align: left;
  }
  
  .solucion-informacion {
    width: 51%;
    right: 0px !important;
    left: auto !important; 
    top: 20% !important;
    padding-top: 0.25rem !important;
    padding-right: 0rem;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-2 {
    font-size: 3rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: auto;
    height: 4rem;
    width: 54% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-2 {
    background-color: #ff9801;
    margin-top: 2%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 7%;
    padding-left: 0px;
    margin-right: 15%;
    margin-left: 15%;
    list-style-type: none;
    text-align: right;
  }
     
  .solucion-marketing {
    width: 61% !important;
    top: 0px !important;
    left: 0% !important;
    padding-top: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-3 {
    font-size: 3rem;
    vertical-align: middle !important;
    padding-left: 0rem !important;
    margin-right: 0rem !important;
    height: 4rem !important;
    width: 90% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-3 {
    background-color: #ff9801;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0rem;
    margin-left: 5%;
    list-style-type: none;
    text-align: left;
  }
  
  .solucion-webadmin {
      width: 56% !important;
      top: 51% !important;
      left: 44% !important;
      padding-top: 0.25rem !important;
      color: #fff !important;
      text-align: center !important;
  }
  
  .item-titulo-4 {
    font-size: 3rem;
    vertical-align: middle !important;
    height: 4rem !important;
    width: 100% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-4 {
    background-color: #ff9801;
    margin-top: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    margin-left: 0px;
    list-style-type: none;
    text-align: right;
    margin-right: 33%;
  }
  
  .solucion-cliente {
    width: 61% !important;
    top: 0px !important;
    left: 0% !important;
    padding-top: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
    
  .item-titulo-5 {
    font-size: 3rem;
    vertical-align: middle !important;
    padding-left: 0rem !important;
    margin-right: 0rem !important;
    height: 4rem !important;
    width: 81% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-5 {
    background-color: #ff9801;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    margin-left: 2%;
    list-style-type: none;
    text-align: left;
    width: 70%;
  }

  .only-on-large {
    max-width: 1440px !important;
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (max-width: 1440px) {
  .titulo-welcome {
      flex: 0 0 auto;
      font-size: 4.9rem;
      text-align: right;
      margin-right: -8px;
  }
  
  .subtitulo-welcome {
    font-size: 1.9rem;
    text-align: right;
  }

  .ventaja-card {
    flex: 0 0 auto !important;
    width: 20% !important;
    padding-bottom: 4rem;
    padding-top: 2rem;
  }
  
  .seccion-subtitulo {
    width: 700px !important;
    color: white;
    font-size: 1.1rem !important;
  }

  .problema-card {
    flex: 0 0 auto !important;
    width: 20% !important;
    align-content: center;
    padding-bottom: 3rem;
  }
  
  .titulo-problema {
    color: #ff9801;
    font-weight: 600;
    font-size: 1.5rem !important;
    background-color: white;
    vertical-align: middle;
    display: grid;
    height: 60px !important;
    width: 100% !important;
    margin-right: auto;
    align-content: center;
  }
  
.solucion-postventa {
    width: 50%;
    right: 17% !important;
    top: 50% !important;
    left: 0% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-1 {
    font-size: 219%;
    vertical-align: middle !important;
    padding-left: 0px !important;
    margin-right: 5% !important;
    height: 4rem !important;
    width: 95% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-1 {
    background-color: #ff9801;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    margin-left: 10%;
    list-style-type: none;
    text-align: left;
  }
  
  .solucion-informacion {
    width: 51%;
    right: 0px !important;
    left: auto !important; 
    top: 20% !important;
    padding-top: 0.25rem !important;
    padding-right: 0rem;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-2 {
    font-size: 3rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: 27%;
    height: 4rem;
    width: 73% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-2 {
    background-color: #ff9801;
    margin-top: 2%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 7%;
    padding-left: 0px;
    margin-right: 15%;
    margin-left: 15%;
    list-style-type: none;
    text-align: right;
  }
     
  .solucion-marketing {
    width: 61% !important;
    top: 0px !important;
    left: 0% !important;
    padding-top: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-3 {
    font-size: 3rem;
    vertical-align: middle !important;
    padding-left: 0rem !important;
    margin-right: 0rem !important;
    height: 4rem !important;
    width: 90% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-3 {
    background-color: #ff9801;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0rem;
    margin-left: 5%;
    list-style-type: none;
    text-align: left;
  }
  
  .solucion-webadmin {
      width: 56% !important;
      top: 51% !important;
      left: 44% !important;
      padding-top: 0.25rem !important;
      color: #fff !important;
      text-align: center !important;
  }
  
  .item-titulo-4 {
    font-size: 219%;
    vertical-align: middle !important;
    height: 4rem !important;
    width: 100% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-4 {
    background-color: #ff9801;
    margin-top: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    margin-left: 0px;
    list-style-type: none;
    text-align: right;
    margin-right: 33%;
  }
  
  .solucion-cliente {
    width: 61% !important;
    top: 0px !important;
    left: 0% !important;
    padding-top: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
    
  .item-titulo-5 {
    font-size: 2.5rem;
    vertical-align: middle !important;
    padding-left: 0rem !important;
    margin-right: 0rem !important;
    height: 4rem !important;
    width: 90% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-5 {
    background-color: #ff9801;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    margin-left: 2%;
    list-style-type: none;
    text-align: left;
    width: 70%;
  }
  
  .only-on-large {
    max-width: 1440px !important;
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  
}

@media (max-width: 1024px) {
  .titulo-welcome {
      flex: 0 0 auto;
      font-size: 4rem;
      margin-right: -8px;
  }
  
  .subtitulo-welcome {
    font-size: 1.7rem;
  }

  .ventaja-card {
    flex: 0 0 auto !important;
    width: 20% !important;
    padding-bottom: 4rem;
    padding-top: 2rem;
  }
  
  .seccion-subtitulo {
    width: 700px !important;
    color: white;
    font-size: 1.1rem !important;
  }

  .problema-card {
    flex: 0 0 auto !important;
    width: 25% !important;
    align-content: center;
    padding-bottom: 3rem;
  }
  
  .titulo-problema {
    color: #ff9801;
    font-weight: 600;
    font-size: 1.5rem !important;
    background-color: white;
    vertical-align: middle;
    display: grid;
    height: 60px !important;
    width: 100% !important;
    margin-right: auto;
    align-content: center;
  }
  

  .solucion-postventa {
    width: 59%;
    right: 17% !important;
    top: 43% !important;
    left: 0% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-1 {
    font-size: 2rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    margin-right: 5% !important;
    height: 3rem !important;
    width: 87% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-1 {
    background-color: #ff9801;
    margin-top: 0.2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 2rem;
    margin-left: 10%;
    list-style-type: none;
    text-align: left;
  }
  
  .solucion-informacion {
    width: 51%;
    right: 0px !important;
    left: auto !important; 
    top: 20% !important;
    padding-top: 0.25rem !important;
    padding-right: 0rem;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-2 {
    font-size: 2rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: auto;
    height: 3rem;
    width: 54% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-2 {
    background-color: #ff9801;
    margin-top: 2%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2%;
    padding-left: 0px;
    margin-right: 1%;
    margin-left: 15%;
    list-style-type: none;
    text-align: right;
  }
     
  .solucion-marketing {
    width: 88% !important;
    top: 0px !important;
    left: 0% !important;
    padding-top: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-3 {
    font-size: 2rem;
    vertical-align: middle !important;
    padding-left: 0rem !important;
    margin-right: 0rem !important;
    height: 3rem !important;
    width: 40% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-3 {
    background-color: #ff9801;
    margin-top: 0.1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0rem;
    margin-left: 5%;
    list-style-type: none;
    text-align: left;
  }
  
  .solucion-webadmin {
    width: 69% !important;
    top: 51% !important;
    left: 31% !important;
    padding-top: 0.25rem !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-4 {
    font-size: 2rem;
    vertical-align: middle !important;
    height: 3rem !important;
    width: 100% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-4 {
    background-color: #ff9801;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 2rem;
    margin-left: 0px;
    list-style-type: none;
    text-align: right;
    margin-right: 5%;
  }
  
  .solucion-cliente {
    width: 61% !important;
    top: 0px !important;
    left: 0% !important;
    padding-top: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
    
  .item-titulo-5 {
    font-size: 2rem;
    vertical-align: middle !important;
    padding-left: 0rem !important;
    margin-right: 0rem !important;
    height: 3rem !important;
    width: 81% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-5 {
    background-color: #ff9801;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-left: 2%;
    text-align: left;
    width: 96%;
    list-style-type: none;
  }
  
  .only-on-large {
    max-width: 1440px !important;
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  

}

@media (max-width: 768px) {
  .titulo-welcome {
      flex: 0 0 auto;
      font-size: 3rem;
  }

  .subtitulo-welcome {
    font-size: 1.5rem;
  }

  .ventaja-card {
    flex: 0 0 auto;
    margin-left: auto;
    margin-right: auto;
    width: 353px !important;
  }
  .titulo-item-ventaja {
    width: fit-content;
    width: 353px !important;
  }
  .seccion-subtitulo {
    width: 410px !important;
    color: white;
    font-size: 1.1rem !important;
  }
  
  .problema-card {
    flex: 0 0 auto !important;
    width: auto !important;
    align-content: center;
    padding-bottom: 3rem;
  }
  
  .titulo-problema {
    color: #ff9801;
    font-weight: 600;
    font-size: 1.5rem !important;
    background-color: white;
    vertical-align: middle;
    display: grid;
    height: 60px !important;
    width: 100% !important;
    margin-right: auto;
    align-content: center;
  }
  
.solucion-postventa {
    width: 61% !important;
    right: 17% !important;
    top: 3% !important;
    left: 0% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-1 {
    font-size: 1.5rem !important;
    vertical-align: middle !important;
    padding-left: 0px !important;
    margin-right: 5% !important;
    height: 2.1rem !important;
    width: 72% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-1 {
    background-color: #ff9801;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    margin-top: 0.2rem !important;
    margin-left: 1% !important;
    list-style-type: none !important;
    text-align: left !important;
    font-size: 0.9rem !important;
  }
  
  .solucion-informacion {
    width: 62%;
    right: 0px !important;
    left: auto !important;
    top: 20% !important;
    padding-top: 0.25rem !important;
    padding-right: 0rem;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-2 {
    font-size: 2rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: auto;
    height: 3rem;
    width: 54% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-2 {
    background-color: #ff9801;
    margin-top: 2%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2%;
    padding-left: 0px;
    margin-right: 1%;
    margin-left: 15%;
    list-style-type: none;
    text-align: right;
    font-size: 0.9rem;
  }
     
  .solucion-marketing {
    width: 97% !important;
    top: 0px !important;
    left: 0% !important;
    padding-top: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-3 {
    font-size: 1.5rem;
    vertical-align: middle !important;
    padding-left: 0% !important;
    margin-right: 0rem !important;
    height: 2rem !important;
    width: 29% !important;
    color: white;
    background-color: #5c5b60;
    margin-left: 6%;
  }
  
  .item-texto-3 {
    background-color: #ff9801;
    margin-top: 0.1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0rem;
    margin-left: 1%;
    list-style-type: none;
    text-align: left;
    font-size: 0.9rem;
    padding-left: 1%;
  }
  
  .solucion-webadmin {
    width: 63% !important;
    top: 40% !important;
    left: 34% !important;
    padding-top: 0.25rem !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-4 {
    font-size: 1.5rem;
    vertical-align: middle !important;
    height: 2rem !important;
    width: 92% !important;
    color: white;
    background-color: #5c5b60;
    margin-left: 13%;
  }
  
  .item-texto-4 {
    background-color: #ff9801;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    margin-left: 0px;
    list-style-type: none;
    text-align: right;
    margin-right: 0%;
    font-size: 0.9rem;
  }
  
  .solucion-cliente {
    width: 74% !important;
    right: 17% !important;
    top: 2% !important;
    left: 0% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
    
  .item-titulo-5 {
    font-size: 1.5rem !important;
    vertical-align: middle !important;
    padding-left: 0px !important;
    margin-right: 5% !important;
    height: 2.1rem !important;
    width: 72% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-5 {
    background-color: #ff9801;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    margin-top: 0.2rem !important;
    margin-left: 1% !important;
    list-style-type: none !important;
    text-align: left !important;
    font-size: 0.9rem !important;
  }
  
  .only-on-large {
    max-width: 1440px !important;
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  
}

@media (max-width: 425px) {
  .fixed_company-detail {
    right: auto;
  }
    
  .titulo-welcome {
    flex: 0 0 auto;
    font-size: 2.2rem;
    margin-top: -15px;  
    text-align: center;
  }
  
  .subtitulo-welcome {
    font-size: 1.2rem;
    flex: auto;
    text-align: center;
  }

  .problema-titulo {
    font-size: 1.4rem !important;
  }

  .solucion-titulo {
    font-size: 1.4rem !important;
  }

  .ventaja-titulo {
    font-size: 1.4rem !important;
  }

  .text-nosotros {
    text-align: center;
    width: 88%;
    margin-left: 6%;
  }
  
  .seccion-subtitulo {
    width: 310px !important;
    color: white;
    font-size: 1.1rem !important;
  }

  .solucion-postventa {
    width: 77%;
    right: 17% !important;
    top: 0% !important;
    left: 0% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-1 {
    font-size: 1rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    margin-right: 5% !important;
    height: 1.5rem !important;
    width: 95% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-1 {
    display: none;
  }
  
  .solucion-informacion {
    font-size: 1rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: auto;
    height: 1.5rem;
    width: 54% !important;
    color: white;
  }
  
  .item-titulo-2 {
    font-size: 1rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: auto;
    height: 1.5rem;
    width: 100% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-2 {
    display: none;
  }
     
  .solucion-marketing {
    width: 61% !important;
    top: 0px !important;
    left: 0% !important;
    padding-top: 0px !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-3 {
    font-size: 1rem;
    vertical-align: middle !important;
    padding-left: 0rem !important;
    margin-right: 0rem !important;
    height: 1.5rem !important;
    width: 90% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-3 {
    display: none;
  }
  
  .solucion-webadmin {
    width: 56% !important;
    top: 33% !important;
    left: 44% !important;
    padding-top: 0.25rem !important;
    color: #fff !important;
    text-align: center !important;
  }
  
  .item-titulo-4 {
    font-size: 1rem;
    vertical-align: middle !important;
    height: 3rem !important;
    width: 100% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-4 {
    display: none;
  }
  
  .solucion-cliente {
    font-size: 1rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: auto;
    height: 1.5rem;
    width: 85% !important;
    color: white;
  }
    
  .item-titulo-5 {
    font-size: 1rem;
    vertical-align: middle !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: auto;
    height: 1.5rem !important;
    width: 100% !important;
    color: white;
    background-color: #5c5b60;
  }
  
  .item-texto-5 {
    display: none;
  }
  
  .only-on-large {
    max-width: 1440px !important;
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }

}

.col-md-2-5 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.solucion_section {
  padding: 0 0%;
}

.solucion-titulo {
  background-color:#ff9801;
  color:white;
  font-size: 3.5rem;
  letter-spacing: 4px;
  font-weight: 700;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.titulo-item-ventaja {
  color: #ff9801;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.2rem;
  background-color: white;
  vertical-align: middle;
  display: table-cell;
  height: 4.5rem;
  width: 478px;
  padding-top: 0.5rem;
}

.titulo-item-ventaja2 {
  color: #ff9801;
  font-weight: 600;
  font-size: 1.5rem;
  background-color: white;
  vertical-align: bottom;
  display: table-cell;
  height: 3rem;
  width: 258px;
}
.ventaja_section {
  padding: 0 0%;
}

.ventaja-titulo {
  background-color:#ff9801;
  color:white;
  font-size: 3.5rem;
  letter-spacing: 4px;
  font-weight: 700;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 0rem;
}

.marca {
  margin-right: 0rem !important;
}

.btn-nav {
  color: #ffffff !important;
  border-color: #ff9801 !important;
}

.btn-nav:hover {
  color: #ffffff !important;
  background-color: #fda218 !important;
  border-color: #e79315 !important;
}

.btn-form {
  background-color: #ffffff !important;
  border-color: #cbcbcb !important;
  letter-spacing: 1px !important;
  color: #0a0a0a !important;
}

.btn-form:hover {
  background-color: #f7f7f7 !important;
  border-color: #cacaca !important;
}

.site-footer {
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}

@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom: 1rem
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer, .site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#ff9801
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#ff9801
}
.social-icons a.twitter:hover
{
  background-color:#ff9801
}
.social-icons a.linkedin:hover
{
  background-color:#ff9801
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";